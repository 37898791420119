import styled from '@emotion/styled';
import { ColContainer } from 'commonStyles';
import Button from 'components/Button';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import TextInput from 'components/Form/TextInput';
import { FormRow } from 'features/InquiryForm/styledComponents';
import { Form } from 'formik';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
  FONT_FAMILY,
  getThemeBrandedColor,
  getThemeButtonImportantColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputBorderShape,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { themev2 } from 'theme-v2';
import { IColor } from 'types';
import hideable from 'utils/hideable';

export const HeroHomePageLogosContainer = styled.div<ITheme>((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
  gridRow: 1 / 1,
  gridColumn: 2 / 2,
  height: 'auto',
  p: {
    margin: 0,
    lineHeight: '20px',
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    fontWeight: 400,
    fontFamily: getThemeFontFamily(props),
    color: getThemeMainColor(props),
    [MEDIA_QUERIES.TABLET]: {
      textAlign: CSS_ALIGN.CENTER,
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    gridRow: '3 / 4',
    gridColumn: '1 / 2',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const HeroHomePageLogos = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  flexWrap: CSS_FLEX_WRAP.WRAP,
  alignItems: CSS_ALIGN.CENTER,
  div: {
    marginTop: '20px',
    verticalAlign: 'middle',
    display: CSS_DISPLAY.INLINE_BLOCK,
    img: {
      display: CSS_DISPLAY.INLINE_BLOCK,
      height: 'auto',
      width: 'auto',
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: CSS_JUSTIFY_CONTENT.SPACE_AROUND,
    div: {
      padding: `0 ${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)}`,
      img: {
        width: 'auto',
      },
    },
  },
}));

export const StyledHomePageHeroWrapper = styled.div<IColor & ITheme>(
  (props) => ({
    background: themev2.colors.black.S900,
    overflow: 'hidden',
    padding: `62px 20px ${getThemeGutter(props, TSHIRT_SIZE.XXXL)}`,
    height: 'auto',
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    [MEDIA_QUERIES.TABLET]: {
      padding: `20px 20px ${getThemeGutter(props, TSHIRT_SIZE.XL)}`,
    },
  })
);

export const StyledHomePageHeroContainer = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  margin: '0 auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
}));

export const HeroHomePage = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  gridAutoColumns: '1fr',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.XL),
  rowGap: getThemeGutter(props, TSHIRT_SIZE.XXXL),
  gridTemplateColumns: '1fr 380px',
  [MEDIA_QUERIES.TABLET]: {
    alignItems: CSS_ALIGN.START,
    rowGap: getThemeGutter(props, TSHIRT_SIZE.L),
    gridTemplateColumns: '1fr',
    columnGap: getThemeGutter(props, TSHIRT_SIZE.XXS),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const HeroHomePageTextBlock = styled.div<
  ITheme & { mainColor: string; isReviewOn: boolean }
>(({ mainColor, isReviewOn, ...props }) => ({
  gridColumn: 1 / 2,
  gridRow: 1 / 2,
  display: CSS_DISPLAY.FLEX,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.FLEX_START,
  'h1, h2, h3': {
    marginTop: isReviewOn ? getThemeGutter(props, TSHIRT_SIZE.S) : 0,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
    color: mainColor
      ? mainColor
      : getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
    textAlign: TEXT_ALIGN.LEFT,
    [MEDIA_QUERIES.TABLET]: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      textAlign: CSS_ALIGN.CENTER,
    },
  },
  a: {
    button: {
      minWidth: '200px',
      fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
      lineHeight: '20px',
      margin: 0,
      padding: '15px 0',
      ':hover': {
        padding: '15px 0',
      },
    },
    width: 'auto',
    height: 'auto',
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    color: getThemeMainColor(props),
  },
  [MEDIA_QUERIES.TABLET]: {
    gridRow: 2 / 3,
    gridColumn: 1 / 2,
    marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    alignItems: CSS_ALIGN.CENTER,
    order: 1,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    a: {
      width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    },
  },
}));

export const DescriptionWrapper = styled.div((props) => ({
  p: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    color: getThemeMainColor(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
    [MEDIA_QUERIES.TABLET]: {
      width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
      textAlign: CSS_ALIGN.CENTER,
    },
  },
}));
export const StyledForm = styled(Form)((props) => ({
  padding: getThemeGutter(props, TSHIRT_SIZE.S),
  background: getThemeMainColor(props),
  borderRadius: '12px',
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
}));

export const StyledRowContainer = styled(FormRow)((props) => ({
  gap: getThemeGutter(props, TSHIRT_SIZE.XS),
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  div: {
    margin: 0,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    'div:nth-of-type(2) span': {
      marginTop: getThemeGutter(props, TSHIRT_SIZE.XS),
    },
  },
}));

export const StyledButton = styled(Button)((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.XS),
  whiteSpace: 'nowrap',
  height: '52px',
  width: '100%',
  fontSize: getThemeFontSize(props, TEXT_TYPE.H5),
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginBottom: '20px',
    marginTop: '30px',
    width: '100%',
  },
}));

export const BottomText = styled.p((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  fontSize: getThemeFontSize(props, TEXT_TYPE.SMALL),
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  a: {
    color: getThemeButtonImportantColor(props)?.background.main,
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  },
}));

const FormContent = styled.div((props) => ({
  background: getThemeMainColor(props),
  borderRadius: '12px',
  minHeight: '500px',
  [MEDIA_QUERIES.TABLET]: {
    gridRowStart: 2,
    margin: '0 auto',
    width: '80%',
    height: '100%',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '70%',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
  },
}));

export const HideableFormContent = hideable(FormContent);

export const LogoWrapper = styled.div((props) => ({
  margin: `0 auto ${getThemeGutter(props, TSHIRT_SIZE.S)}`,
}));

export const ModalContainer = styled(ColContainer)<ITheme>((props) => ({
  padding: getThemeGutter(props, TSHIRT_SIZE.M),
  flex: '1',
  height: '100%',
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    padding: 20,
  },
}));
export const StyledTextInput = styled(TextInput)({
  input: {
    marginBottom: 0,
  },
});

export const StyledFeaturesContainer = styled.div<
  ITheme & { isCtaAdded: boolean }
>(({ isCtaAdded, ...props }) => ({
  maxWidth: '1024px',
  width: '100%',
  marginTop: isCtaAdded ? getThemeGutter(props, TSHIRT_SIZE.L) : 0,
  [MEDIA_QUERIES.TABLET]: {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
  },
}));

export const StyledList = styled.ul({
  listStyle: 'none',
  display: CSS_DISPLAY.FLEX,
  flexWrap: CSS_FLEX_WRAP.WRAP,
  padding: 0,
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
  },
});

export const ListItem = styled.li<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  marginRight: getThemeGutter(props, TSHIRT_SIZE.S),
  padding: 0,
  color: getThemeMainColor(props),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
  fontWeight: 400,
  maxHeight: '24px',
}));

export const CheckmarkWrapper = styled.span({
  width: '16px',
  height: 'auto',
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  margin: '8px',
  marginLeft: '0',
});
